import React from "react"

import styles from "./default-content.module.sass"

const DefaultContent = ({ children }) => {
  return (
    <section className={styles.section}>
      <div className="container">
        <div className="row">
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </section>
  )
}

export default DefaultContent
